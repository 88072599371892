.is--home {
  .rwd002-header {
    @include media-up(lg) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      box-shadow: none;
    }
    &__logo-link {
      @include media-up(lg) {
        display: none;
      }
    }
  }
  .rwd002-gnav-item__link {
    @include media-up(lg) {
      color: $white;
      &:not(.is-form) {
        &:hover, &:focus, &.current{ 
          color: #FFB000;
        }
      }
      span:after {
        background-color: #FFB000;
      }
    }
  }
  .cart_list_price{ display: none;}
}

.block-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .banner {
    @include hoverOpacity(.7);
    img {
      max-width: 100%;
      height: auto;
    }
  }

}