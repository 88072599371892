//お試しアカウント発行
.flowCont1 {
  h2.hybs_cont_top_title { 
    @include media-up(lg) {
      margin-bottom: 40px;
      text-align: center;
    }
    img {
      @include media-up(lg) {
        max-width: 100%;
        width: 750px;
        height: auto;
      }
    }
  }

  &__banner {
    text-align: center;
    .banner {
      @include hoverOpacity;
      margin: 0 auto;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  dl {
    padding:30px 0 0;
    dt {
      font-size:16px;
      color:#FF8F02;
      font-weight:bold;}
    dd {
      padding:0 0 27px 15px;
    }
  }
  .hybs_flow{
    background:url('/hs_data/Image/flow_top_flow.jpg') no-repeat;
    padding-right:55px;
    padding-top:357px;
    padding-bottom:61px;
    a {
      display:block;
      margin-bottom:4px;
      zoom:1;
      img { display:block; }
    }
  }

  &__btnwrap {
    text-align: center;
    .btn-inquiry {
      @include hoverOpacity;
      @extend .btn-pill;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      background-color: #ffb000;
      font-weight: $font-weight-bold;
      color: $white;
      @include media-up(lg) {
        width: 320px;
      }
    }
    p {
      margin-top: 15px;
      line-height: 1.7;
      @include media-down(lg) {
        text-align: left;
      }
    }
  }

}
