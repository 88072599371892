@keyframes up_down_line {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

.rwd002-footerSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 70px;
  @include media-up(lg) {
    margin-bottom: 60px;
  }
  &__title{
    letter-spacing: 0.16em;
    margin-right: 1em;
    font-family: 'Lato', $font-family-sans-serif;
  }
  &__btn{
    @extend .btn;
    font-size: 1.8em;
    margin: 0 0.1em;
    line-height: 1;
    transition: $transition-base;
  }
  &__btn.is-fb{
    &:hover,&:focus{
      color: $sns-facebook-bg;
    }
  }
  &__btn.is-tw{
    &:hover,&:focus{
      color: $sns-twitter-bg;
    }
  }
  &__btn.is-line{
    &:hover,&:focus{
      color: $sns-line-bg;
    }
  }
}

.rwd002-pagetop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -50px;
  width: 100px;
  height: 100px;
  font-size: $font-size-xs;
  font-family: "Roboto", $font-family-sans-serif;
  color: $body-color;
  text-decoration: none;
  letter-spacing: 0.1em;
  transform-origin: bottom;
  border: 1px solid $body-color;
  background-color: $white;

  @include media-up(lg) {
    right: map-get($spacers, 5);
  }
  @include media-down(lg) {
    right: calc(50% - 50px);
  }
  &:before {
    content: "";
    display: block;
    margin-bottom: 1em;
    width: 34px;
    height: 21px;
    background: svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.67 21.05"><g fill="none" stroke="#{$dark}" stroke-miterlimit="10"><path d="m.34 20.68 16.5-15 16.5 15"/><path d="m.34 15.65 16.5-15 16.5 15"/></g></svg>'
      )
      no-repeat center / contain;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    &:before {
      animation: up_down_line 0.9s infinite ease-in-out;
    }
  }
  &__wrap {
    width: 100%;
    z-index: 10;
    transition: $transition-base;
    @include media-up(lg) {
      position: fixed;
      position: sticky;
      bottom: 80px;
      margin: 0 auto;
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 1);
    }
    @include media-down(lg) {
      position: relative;
    }
    &.hidden {
      transform: translateY(200px);
      opacity: 0;
    }
  }
}

.rwd002-footer {
  background-color: $tertiary;
  color: $white;
  @include media-up(md) {
    padding: map-get($spacers, 5) map-get($spacers, 5) map-get($spacers, 3);
  }
  @include media-down(lg) {
    padding-top: 90px;
  }
  @include media-down(md) {
    padding: 90px map-get($spacers, 4) map-get($spacers, 3);
  }

  &__inner {
    display: flex;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    @include media-up(lg) {
      min-height: 300px;
    }
    @include media-down(lg) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__info {
    @include media-up(lg) {
      flex-grow: 1;
      padding-right: map-get($spacers, 3);
    }
    @include media-down(lg) {
      margin-bottom: 2em;
    }
  }
  &__companyName {
    font-size: $font-size-base * 1.375;
    font-weight: $font-weight-bold;
    letter-spacing: 0.2em;
    font-feature-settings: "palt";
  }

  &__address {
    font-size: $font-size-sm;
    color: rgba($white, 80%);
    letter-spacing: 0.19em;
    font-feature-settings: "palt";
    &:last-child {
      margin-bottom: 0;
    }
    .zip,
    .addr2 {
      display: none;
    }
  }

  &__copyright,
  &__login {
    display: block;
    text-align: center;
    font-size: $font-size-xxs;
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    color: rgba($white, 50%);
    font-feature-settings: "palt";
  }

  &__copyright {
    @include media-down(md) {
      margin-left: -1.3em;
      margin-right: -1.3em;
    }
  }

  &__login {
    margin-top: 1em;
  }
}

.rwd002-footerNav {
  @include media-up(lg) {
    display: grid;
    grid-template: 1fr auto / 2fr 1fr;
    flex-basis: 60%;
  }
  @include media-down(lg) {
    display: flex;
    flex-wrap: wrap;
  }
  @include media-down(md) {
    display: block;
  }
  &__list,
  &__sublist {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__list {
    font-size: $font-size-base * 0.9375;
    color: rgba($white, 80%);
    @include media-up(md) {
      column-count: 2;
      column-gap: 1em;
    }
    @include media-up(lg) {
      grid-column: 1;
      grid-row: 1 / span 2;
      column-gap: 0;
    }
    @include media-down(lg) {
      margin-right: 2.5em;
    }
    @include media-down(md) {
      flex-grow: 1;
      margin-right: 0;
      margin-left: -0.8em;
      margin-right: -0.8em;
      margin-bottom: 1.5em;
    }
  }
  &__sublist {
    font-size: $font-size-xs;
    color: rgba($white, 60%);
    @include media-up(lg) {
      grid-column: 2;
      grid-row: 1;
    }
    @include media-down(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.rwd002-footerNav-item {
  display: flex;
  flex-wrap: wrap;
  @include media-up(md) {
    padding-right: 1em;
  }
  @include media-down(md) {
    position: relative;
    border: solid rgba($white, 30%);
    border-width: 1px 0;
    & + & {
      border-top-width: 0;
    }
  }
  &__link {
    position: relative;
    display: inline-block;
    padding: 0.3em 1em;
    letter-spacing: 0.18em;
    font-feature-settings: "palt";
    font-weight: $font-weight-bold;
    &:hover,
    &:focus {
      color: $white;
    }
    &:before {
      position: absolute;
      content: "";
      height: 1px;
      width: 0.3em;
      top: 1em;
      left: 0;
      background-color: rgba($white, 80%);
    }
    @include media-down(md) {
      flex-grow: 1;
      flex-basis: calc(100% - 40px);
      padding: 0.55em 40px 0.55em 1.7em;
      &:before {
        top: 1.25em;
        left: 0.7em;
      }
    }
  }
}

.rwd002-footerNav-subitem {
  &__link {
    display: inline-block;
    padding: 0.3em 0;
    margin-bottom: 0.5em;
    letter-spacing: 0.16em;
    font-weight: $font-weight-bold;
    transition: $transition-base;
    &:hover,
    &:focus {
      color: $white;
    }
    @include media-down(lg) {
      margin-bottom: 0;
      margin-right: 2em;
    }
  }
}

.rwd002-footer-subnav {
  @include media-up(md) {
    display: none;
  }
  &__tgl {
    @extend .btn;

    &:focus {
      box-shadow: none;
    }

    @include media-up(md) {
      display: none;
    }
    @include media-down(md) {
      position: relative;
      flex-basis: 40px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 5px);
        width: 10px;
        height: 1px;
        background-color: darken($white, 50%);
        transition: transform 0.2s ease-in-out;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.is-active {
        &:before {
          transform: rotate(180deg);
        }
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
  &__list {
    list-style: none;
    @include media-down(md) {
      padding-left: 1.7em;
      padding-bottom: 1em;
    }
  }
}

.rwd002-footer-subnav-item {
  &__link {
    @include media-down(md) {
      display: block;
      padding: 0.2em 0;
    }
  }
}

.rwd002-footer-search {
  margin-top: 2em;
  @include media-down(lg) {
    width: 100%;
    margin-top: 1.5em;
    margin-bottom: 3em;
  }
  &__inner {
    position: relative;
    max-width: 320px;
    @include media-down(md) {
      margin-left: -0.8em;
      margin-right: -0.8em;
    }
  }
  &__input {
    @extend .form-control;
    padding-right: 2.2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background-color: $white;
    transition: $transition-base;
    border-width: 0;
  }
  &__btn {
    @extend .btn;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(100% - 2.2em);
    width: 2.2em;
    padding: 0 0.5em;
    pointer-events: none;
    transition: $transition-base;
    > svg {
      width: 1em;
      height: 100%;
      fill: tint($dark, 50%);
    }
  }
  &__btn:focus,
  &__input:focus + &__btn {
    pointer-events: auto;
    right: 0;
  }
}
