// 詳細ページカスタム
#main.center .element_detail{
  .entry_cart{
    &:before,
    &:after {
      content: " ";
      display: table;
    }
  
    &:after {
      clear: both;
    }
  
    *zoom: 1;
  }
  .entry{
    .entry_cart .detail_box{
      width: 302px;
      float: right;
    }
    .detail_item{
      display: none;
    }
    .item_detailtxt{
      width: 380px;
      float: left;
    }
  }
}

// 一覧ページカスタム
.element_list{
  .list_sort_links{
    display: none;
  }
  .cart_list_price{
    display: none;
  }
  .paginationbox{
    td:last-child{
      color:#999;
      strong{ font-weight: 400;}
    }
  }
}
#contents > .side{
  .cart_list_price{ display: none;}
}

//スマホ用
.ui-mobile{
  .listContents{
    ul li{
      .listOption2{ display: none;}
    }
  }
  .element_detail{
    .cartDetailPrice,
    .detail_item,
    .detail_guidelink{ display: none;}
  }
  a#kartContent{ display: none;}
  .entry{
    > h5{ display: none;}
    .ui-collapsible-content{
      padding: 0;
      border: none;
    }
  }
}