.is--service {
  &.is--article-14 {
    .rwd002-side {
      display: none;
    }
    .rwd002-container {
      max-width: 100%;
      width: 100%;
      padding: 0 0;
      @include media-up(lg) {
        margin-top: 0;
      }
    }
    .rwd002-main {
      padding: 0 0;
    }
    .rwd002-detail {
      padding: 0;
      &__entry {
        max-width: 100%;
      }
    }
  }
}
// レイアウト
$base1: #FF793C;
$base2: #62C5D4;
$base3: #d33a1e;
$light-grey:#f0f0f0;
$dark-grey: #333;
$grey: #555;

$btn2-bg-color-hover:  lighten($base2,20%);

//button special
$btn-special-bg-color1: #b13e26;
$btn-special-bg-color2: #c52518;
$btn-special-bg-color3: #cb3c31;
$btn-special-bg-color4: #d76544;
$btn-special-bg-color1-light: lighten($btn-special-bg-color1,10%);
$btn-special-bg-color2-light: lighten($btn-special-bg-color2,10%);
$btn-special-bg-color3-light: lighten($btn-special-bg-color3,10%);
$btn-special-bg-color4-light: lighten($btn-special-bg-color4,10%);
$btn-special-bg-origin1:   0%;
$btn-special-bg-origin2:  25%;
$btn-special-bg-origin3:  75%;
$btn-special-bg-origin4: 100%;
$btn-special-text-color: $white;
$btn-special-border-color: $white;
$btn-special-bg-color-hover: $base1;
$btn-special-bg-color1-hover: lighten($btn-special-bg-color1,10%);
$btn-special-bg-color2-hover: lighten($btn-special-bg-color2,10%);
$btn-special-bg-color3-hover: lighten($btn-special-bg-color3,10%);
$btn-special-bg-color4-hover: lighten($btn-special-bg-color4,10%);
$btn-special-text-color-hover: $white;
$btn-special-border-color-hover: $btn2-bg-color-hover;

//Horizontal Gradient
@mixin horizontal-gradient4($start: $white, $start-origin: 0%, $start2: $white, $start-origin2: 33.3333%, $start3: $white, $start-origin3: 66.6666%, $stop: $black, $stop-origin: 100%) {
  background-color: $start;
  background-image: -webkit-linear-gradient(left, $start $start-origin, $start2 $start-origin2, $start3 $start-origin3, $stop $stop-origin);
  background-image: -moz-linear-gradient(left, $start $start-origin, $start2 $start-origin2, $start3 $start-origin3, $stop 100%);
  background-image: -ms-linear-gradient(left, $start $start-origin, $start2 $start-origin2, $start3 $start-origin3, $stop $stop-origin);
  background-image: linear-gradient(to right, $start $start-origin, $start2 $start-origin2, $start3 $start-origin3, $stop $stop-origin);
}

@mixin ribbon-title($color:$grey){
  color: $white;
  text-align: center;
  position: relative;
  background-color: $color;
  font-size: $font-size-base * 1.1875;
  font-weight: 600;
  padding: .625rem;
  @include media-down(lg) {
    font-size: $font-size-base * .8125;
    padding: .3125rem;
  }
  >span{
    @include media-down(lg) {
      display: table-cell;
      height: 3em;
      width: 999em;
      width: 33.333vw;
      vertical-align: middle;
      text-align: center;
    }
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: transparent;
    top: 100%;
  }
  &:before {
    left: 0;
    border-width: 0 4px 4px 0;
    border-right-color: darken($color,20%);
    @include media-down(lg) {
      border-width: 0 2px 2px 0;
    }
  }
  &:after {
    right: 0;
    border-style: solid;
    border-width: 4px 4px 0 0;
    border-top-color: darken($color,20%);
    @include media-down(lg) {
      border-width: 2px 2px 0 0;
    }
  }

}

.price{
  &__table{
    padding-top: 50px;
    background-color: darken($light-grey,3%);
    @include media-down(lg) {
      padding-bottom: 1rem;
    }
    &__container{
      padding: 0 .625rem;
      @include media-up(lg) {
        max-width: 1000px;
      }
    }
    &__block{
      background: $white;
      border-radius: 6px;
      margin-bottom: 20px;
      padding: 22px;
      @include media-down(xl) {
        padding: 15px;
      }
      @include media-down(lg) {
        height: calc(100% - 10px);
        margin-bottom: .625rem;
        padding: 8px 7px 15px;
      }
    }
    &__row{
      margin-left: -11px;
      margin-right: -11px;
      @include media-down(lg) {
        margin-left: -.625rem;
        margin-right: -.625rem;
      }
      .price__table__row{
        @include media-down(lg) {
          margin-left: -.2rem;
          margin-right: -.2rem;
        }
      }
    }
    &__column{
      padding-left: 11px;
      padding-right: 11px;
      @include media-down(lg) {
        padding-left: 3px;
        padding-right: 3px;
      }
    }
    .column-xsmall-1-3{
      @include media-down(lg) {
        padding-left: .3rem;
        padding-right: .3rem;
        margin-bottom: 0;
      }
    }
    .column-1{
      @include media-down(lg) {
        margin-bottom: 0;
      }
    }
  }
  &__table .block{
    position: relative;
    &:before,
    &:after{
      display: block;
      content: '';
      color: #999;
      position: absolute;
      left: 50%;
      width: 32px;
      height: 32px;
      background: 50%/contain no-repeat;
      background-image: svgUrlFunc('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#{$gray-600}"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM256 368C269.3 368 280 357.3 280 344V280H344C357.3 280 368 269.3 368 256C368 242.7 357.3 232 344 232H280V168C280 154.7 269.3 144 256 144C242.7 144 232 154.7 232 168V232H168C154.7 232 144 242.7 144 256C144 269.3 154.7 280 168 280H232V344C232 357.3 242.7 368 256 368z"/></svg>');
      line-height: 1;
      transform: translateX(-50%);
      z-index: 1;
      @include media-down(lg) {
        width: 16px;
        height: 16px;
        font-size: $font-size-base;
      }
    }
    &:before{
      top: -29px;
      @include media-down(lg) {
        top: -13px;
      }
    }
    &:after{
      bottom: -28px;
      @include media-down(lg) {
        bottom: -13px;
      }
    }
    &__lead{
      text-align: center;
      color: lighten($grey,20%);
      font-size: $font-size-sm;
      line-height: 1.5;
      @include media-down(lg) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 5em;
        margin: 0;
        font-size: $font-size-base * .6875;
        font-feature-settings: 'palt';
        text-align: center;
      }
    }
    &__title{
      margin-left: -26px;
      margin-right: -26px;
      @include ribbon-title;
      @include media-down(xl) {
        margin-left: -19px;
        margin-right: -19px;
      }
      @include media-down(lg) {
        margin-left: -9px;
        margin-right: -9px;
      }
    }
    &__subtitle{
      margin-left: -22px;
      margin-right: -22px;
      text-align: center;
      color: $dark-grey;
      font-size: $font-size-sm;
      padding: 1.35rem;
      font-weight: 600;
      letter-spacing: .1em;
      @include media-down(xl) {
        margin-left: -15px;
        margin-right: -15px;
        padding: .3125rem;
      }
      @include media-down(lg) {
        margin-left: -7px;
        margin-right: -7px;
        font-size: $font-size-base * .6875;
        padding: .3125rem;
      }
      >span{
        @include media-down(lg) {
          display: table-cell;
          height: 3em;
          width: 999em;
          width: 33.333vw;
          vertical-align: middle;
          text-align: center;
        }
      }
    }
    &__price{
      text-align: center;
      color: $dark-grey;
      font-size: $font-size-base * 3.5;
      font-family: Roboto, sans-serif;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1;
      @include media-down(lg) {
        font-size: $font-size-base * 2.5;
      }
      @include media-down(md) {
        font-size: $font-size-base * 2.0625;
      }
      >small{
        font-size: .3em;
        letter-spacing: .1em;
        display: inline-block;
        margin-left: .1em;
      }
    }
    &__point{
      list-style: none;
      padding-left: 0;
      @include media-down(lg) {
        margin-top: 1em;
      }
      &__item{
        margin-left: 0;
        margin-bottom: .625rem;
        padding-left: 0;
        padding-bottom: .625rem;
        border-bottom: 1px solid $light-grey;
        font-size: $font-size-xs;
        line-height: 1.8;
        letter-spacing: .09em;
        @include media-down(lg) {
          font-size: $font-size-base * .6875;
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }
    .button{
      margin: 0 auto;
      @extend .btn-pill;
      &-medium{
        @include hoverOpacity;
        @extend .btn;
        @extend .btn-pill;
        background-color: $base3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-sm;
        color: $white;
        line-height: 1.3;
        @include media-up(lg) {
          width: 176px;
          height: 42px;
          margin-left: auto;
          margin-right: auto;
          font-size: $font-size-base;
        }
        &:not(.disabled):hover,
        &:not(:disabled):focus {
          color: $white !important;
        }
      }
      &-border{
        @extend .btn;
        font-size: $font-size-xs;
        line-height: 1;
        &:hover{
          color: $white;
        }
      }
    }

    // ブロック種類別
    &--basic{
      padding-top: 18px;
      padding-bottom: 18px;
      text-align: center;
      @include media-down(lg) {
        padding-left: .9375rem;
        padding-right: .9375rem;
      }
      &:before, &:after{
        display: none;
      }
      >p{
        margin-top: 0;
        margin-bottom: 0;
        display: inline-block;
        text-align: center;
        font-weight: 600;
        font-size: $font-size-base * 1.1875;
        color: $dark-grey;
        vertical-align: middle;
        @include media-down(lg) {
          font-size: $font-size-base * 1.0625;
        }
        &:first-child{
          margin-right: 3em;
          @include media-down(lg) {
            margin: 0 .5em;
          }
        }
      }
      .text-grey{
        color: #777 !important;
        display: inline-block;
        margin-right: 1em;
      }
      .price{
        font-size: $font-size-base * 3;
        line-height: 1;
        font-family: Roboto, sans-serif;
        >small{
          font-size: .4em;
        }
        @include media-down(lg) {
          font-size: $font-size-base * 2.375;
        }
      }
    }
    &--01{
      padding-top: 30px;
      @include media-up(lg) {
        height: calc(100% - 20px);
      }
      @include media-down(lg) {
        padding-top: 0;
      }
      &:before,&:after{
        display: none;
      }
      .block__title{
        @include ribbon-title($secondary);
        @include horizontal-gradient4(
          $btn-special-bg-color1,$btn-special-bg-origin1,
          $btn-special-bg-color2,$btn-special-bg-origin2,
          $btn-special-bg-color3,$btn-special-bg-origin3,
          $btn-special-bg-color4,$btn-special-bg-origin4
        );
        @include media-down(lg) {
          >span{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          }
        }
      }
      .block__subtitle{
        background-color: rgba($btn-special-bg-color4,15%);
        @include media-down(lg) {
          >span{
            width: 100vw;
          }
        }
      }
      .block__price{
        font-size: $font-size-base * 5.625;
        @include media-down(lg) {
          font-size: $font-size-base * 4.375;
        }
        @include media-down(md) {
          font-size: $font-size-base * 2.75;
          margin-top: .625rem;
          margin-bottom: .3125rem;
          &+p{
            margin-top: 0;
            margin-bottom: .3125rem;
          }
        }
      }
      .button-wrapper.margin-bottom-medium{
        @include media-down(lg) {
          margin-bottom: 1.25rem;
        }
      }
      .block__point{
        margin-bottom: .625rem;
        @include media-down(lg) {
          column-count: 2;
          column-gap: .625rem;
          margin-bottom: .625rem;
          .block__point__item{
            display: inline-block;
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: .625rem;
            border-top: 1px solid $light-grey;
            border-bottom: none;
            line-height: 1.6;
            &:first-child,
            &:nth-child(3n+1){
              border-top: none;
            }
          }
        }
        @media (max-width: 320px){
          letter-spacing: 0;
        }
      }
      .button{
        &-medium{
          @include horizontal-gradient4(
            $btn-special-bg-color1,$btn-special-bg-origin1,
            $btn-special-bg-color2,$btn-special-bg-origin2,
            $btn-special-bg-color3,$btn-special-bg-origin3,
            $btn-special-bg-color4,$btn-special-bg-origin4
          );
          &:hover, &:focus{
            color: $white;
            @include horizontal-gradient4(
              $btn-special-bg-color1,$btn-special-bg-origin1,
              $btn-special-bg-color2,$btn-special-bg-origin2,
              $btn-special-bg-color3,$btn-special-bg-origin3,
              $btn-special-bg-color4,$btn-special-bg-origin4
            );
          }
          @include media-down(xl) {
            border-radius: 1.2em;
            padding: .625rem;
          }
        }
        &-border{
          color: $secondary;
          border-color: $secondary;
          &:hover{
            @include horizontal-gradient4(
              $btn-special-bg-color1,$btn-special-bg-origin1,
              $btn-special-bg-color2,$btn-special-bg-origin2,
              $btn-special-bg-color3,$btn-special-bg-origin3,
              $btn-special-bg-color4,$btn-special-bg-origin4
            );
          }
        }
      }
    }
    &--02,
    &--03,
    &--04{
      padding-top: 10px;
      @include media-up(lg) {
        height: calc(100% - 20px);
      }
      .block__lead{
        @include media-up(lg) {
          margin-bottom: 10px;
        }
      }
      .block__point{
        min-height: 120px;
        margin-bottom: 0;
        @include media-down(xl) {
          min-height: 140px;
        }
        @include media-down(lg) {
          min-height: 165px;
        }
      }
      .button{
        &-medium{
          @include media-down(lg) {
            border-radius: 1.2em;
            padding: .625rem;
          }
        }
      }
    }
    &--02{
      .block__title{
        @include ribbon-title($base2);
      }
      .block__subtitle{
        background-color: rgba($base2,15%);
      }
      .button{
        &-medium{
          background-color: $base2;
        }
        &-border{
          color: $base2;
          border-color: $base2;
          &:hover{
            background-color: $base2;
          }
        }
      }
    }
    &--03{
      .block__title{
        @include ribbon-title($base1);
      }
      .block__subtitle{
        background-color: rgba($base1,15%);
      }
      .button{
        &-medium{
          background-color: $base1;
        }
        &-border{
          color: $base1;
          border-color: $base1;
          &:hover{
            background-color: $base1;
          }
        }
      }
    }
    &--04 {
      .block__title{
        @include ribbon-title($base3);
      }
      .block__subtitle{
        background-color: rgba($base3,15%);
      }
      .button{
        &-border{
          color: $base3;
          border-color: $base3;
          &:hover{
            background-color: $base3;
          }
        }
      }
    }
  }
  // すべてのプランで
  &__feature{
    padding-bottom: 3.125rem;
    @include media-down(lg) {
      overflow: hidden;
    }
    &__container{
      padding-top: 3.125rem;
      @include media-up(lg) {
        max-width: 1000px;
      }
      @include media-down(lg) {
        --bs-gutter-x: 1.25rem;
      }
    }
    &__title{
      font-size: $font-size-base * 1.75;
      text-align: center;
      font-weight: 600;
      &,div.entry &{
        margin-bottom: 2.5rem;
      }
    }
    &__support{
      list-style: none;
      text-align: center;
      padding-left: 0;
      &__item{
        padding-left: 0;
        font-size: $font-size-base;
        margin-left: 0;
        margin-bottom: .9375rem;
      }
    }
    &__row + p.text-center{
      margin-top: 0;
    }
    .btn-link {
      color: $base1;
    }
  }
  &__feature .block{
    margin-bottom: 2.5rem;
    &__title{
      font-size: $font-size-base;
      font-weight: 600;
    }
    &__desc{
      line-height: 1.7;
      font-feature-settings: 'palt';
      letter-spacing: .1em;
      margin-top: .625rem;
    }
  }

  // 共通オプション
  &__option{
    background-color: darken($light-grey,3%);
    &__container{
      --bs-gutter-x: 1.25rem;
      padding-top: 3.125rem;
      padding-bottom: 3.125rem;
      @include media-up(lg) {
        max-width: 1000px;
      }
      @include media-down(lg) {
        padding-left: .625rem;
        padding-right: .625rem;
      }
    }
    &__row{
      @include media-up(lg) {
        justify-content: center;
      }
      @include media-down(lg) {
        margin-left: -.625rem;
        margin-right: -.625rem;
        gap: 15px 0;
        line-height: 1.4;
      }
    }
    &__column{
      @include media-down(lg) {
        padding-left: .625rem;
        padding-right: .625rem;
        &:nth-child(2n+1){
          clear: both;
        }
      }
    }
    &__title{
      font-size: $font-size-base * 1.75;
      text-align: center;
      font-weight: 600;
      &,div.entry &{
        margin-bottom: 2.5rem;
      }
      @include media-down(lg) {
        font-size: $font-size-base * 1.375;
      }
    }
  }
  &__option .block{
    .svgicon{
      width: 100%;
      height: 70px;
      margin-bottom: 1.25rem;
      fill: $gray-600;
      @include media-down(lg) {
        height: 3.125rem;
        width: 3.125rem;
        display: block;
        margin: 0 auto 1.25rem;
      }
    }
    &__name,
    &__price{
      color: $dark-grey;
      font-weight: 600;
      text-align: center;
    }
    &__name{
      @include media-down(lg) {
        display: block;
        line-height: 1.4;
        font-size: $font-size-xs;
      }
    }
    &__price{
      font-size: $font-size-sm;
      letter-spacing: .1em;
      @include media-down(lg) {
        margin: 1.25rem 0;
        font-size: $font-size-xs;
      }
    }
    &__price .font-size-large{
      display: inline-block;
      margin: 0 .2em;
      font-size: $font-size-base * 1.6875;
      font-family: Roboto, sans-serif;
      @include media-down(lg) {
        font-size: $font-size-base * 1.375;
      }
    }
    &__price small{
      font-size: $font-size-xs;
      font-feature-settings: 'palt';
      letter-spacing: .1em;
    }
  }
}
