// Color variables (appears count calculates by raw css)
$light-gray: #CCC; // Appears 7 times
$black-gray: #111111; // Appears 4 times
$dark-gray: #444444; // Appears 9 times
$deep-gray: #bbbbbb; // Appears 4 times
$gray: #cccccc; // Appears 22 times
$color1: #9966ff; // Appears 12 times
$color2: #55c801; // Appears 10 times
$color3: #ff8702; // Appears 10 times
$color4: #09aae6; // Appears 10 times
$color6: #3c67ba; // Appears 8 times
$color7: #19b7eb; // Appears 8 times
$color12: #ffffcc; // Appears 4 times

.serviceCont1 {
  .hybs_cont_top_title {
    background:url(/hs_data/Image/service_top_title.gif) no-repeat;
    height:45px;
  }
  .exbox {
    background:url(/hs_data/Image/service_top_box_bg.gif) repeat-y;
  }
  .box1 {
    background:url(/hs_data/Image/service_top_box1_bgtop.gif) no-repeat top;
    dl {
      background:url(/hs_data/Image/service_top_box1_bgbtm.gif) no-repeat bottom;
      padding:0 20px 40px 32px;
    }
    strong.hybs_img {
      background:url(/hs_data/Image/service_top_box1_title.gif) no-repeat 42px 27px;
      height:100px;
      padding:27px 19px 7px 42px;
    }
    dt {
      color:$color6;
      font-size:20px;
      line-height:1em;
      padding-bottom:3px;
      padding-left:43px;
      padding-top:21px;
    }
    dd {
      padding-bottom:3px;
      padding-left:43px;
    }
    dt.li1 {
      background:url(/hs_data/Image/service_top_box1_listmark1.gif) no-repeat;
    }
    dt.li2 {
      background:url(/hs_data/Image/service_top_box1_listmark2.gif) no-repeat;
    }
    dt.li3 {
      background:url(/hs_data/Image/service_top_box1_listmark3.gif) no-repeat;
    }
    dt.li4 {
      background:url(/hs_data/Image/service_top_box1_listmark4.gif) no-repeat;
    }
    dt.li5 {
      background:url(/hs_data/Image/service_top_box1_listmark5.gif) no-repeat;
    }
  }
  .box2 {
    background:url(/hs_data/Image/service_top_box2_bgtop.gif) no-repeat top;
    padding:35px;
    .hybs_img {
      background:url(/hs_data/Image/service_top_box2_title.gif) no-repeat;
      height:33px;
    }
    p {
      padding-bottom:10px;
    }
  }
  .box3 {
    background:url(/hs_data/Image/service_top_box2_bgtop.gif) no-repeat top;
    .hybs_img {
      background:url(/hs_data/Image/service_top_box3_title.gif) no-repeat;
      height:33px;
    }
    div.box3inside {
      background:url(/hs_data/Image/service_top_box1_bgbtm.gif) no-repeat bottom;
      padding:35px;
    }
    p {
      padding:10px 0;
    }
    p.bottom {
      font-size:12px;
    }
  }
  .banbox {
    background:$white;
    margin-bottom:5px;
    .hybs_img {
      background:url(/hs_data/Image/service_top_banbox_title.gif) no-repeat;
      height:223px;
    }
    .banboxtable {
      padding:7px 5px 12px;
      table {
        td {
          padding:0 2px;
        }
      }
    }
  }
  .banbox2_3 {
    border:1px solid $light-gray;
    .hybs_img {
      background:url(/hs_data/Image/service_top_banbox2_3_title.gif) no-repeat;
      height:41px;
    }
  }
  .banbox2 {
    background:url(/hs_data/Image/service_top_banbox2_3_bg.gif) repeat-x;
    table {
      border-collapse:collapse;
      td {
        border:1px solid $light-gray;
      }
    }
    .hybs_img {
      background:url(/hs_data/Image/service_top_banbox2_title.gif) no-repeat;
      height:20px;
    }
  }
  .banbox2_3table {
    padding:4px 7px 24px;
  }
  .banbox3 {
    .hybs_img {
      background:url(/hs_data/Image/service_top_banbox3_title.gif) no-repeat;
      height:20px;
    }
    dl {
      padding:7px;
    }
    dt {
      padding-bottom:2px;
      img {
        display:block;
      }
    }
  }
}

// 多彩な機能
.serviceCont2 {
  table.service_top_ico_table {
    tr {
      @include media-down(lg) {
        display: flex;
        flex-wrap: wrap;
      }
    }
    th, td {
      @include media-up(lg) {
        padding: 8px 0;
      }
      @include media-down(lg) {
        margin: 6px 0;
      }
    }
    th {
      padding-right: 10px;
      @include media-up(lg) {
        width: calc(65px + 10px);
      }
      @include media-down(lg) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
      }
      .icon {
        @include media-up(lg) {
          width: 100%;
          height: auto;
        }
      }
    }
    td {
      color:$dark-gray;
      font-size:12px;
      height: 80px;
      @include media-down(lg) {
        width: 80%;
      }
      a {
        text-decoration: underline;
      }
      strong {
        font-size:16px;
        line-height:1.1em;
      }
      sup {
        font-weight:bold;
        line-height:1em;
      }
    }
  }
  h2.hybs_cont_top_title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include media-up(lg) {
      margin-bottom: 24px;
    }
    @include media-down(lg) {
      flex-direction: column;
    }
    span {
      font-weight: $font-weight-bold;
      font-size: $font-size-base * 2;
      color: $primary;
    }
    small {
      font-weight: $font-weight-bold;
      color: $gray-700;
      letter-spacing: .1em;
      font-size: $font-size-sm;
      @include media-up(lg) {
        margin-left: 1rem;
      }
      @include media-down(lg) {
        margin-top: 0.2em;
      }
    }
  }
  strong.hybs_img {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    padding: 4px 10px;
    border-radius: 100em;
    background-color: #125FB9;
    color: $white;
    line-height: 1.4;
    letter-spacing: .2em;
    text-indent: 0.2em;
    text-align: center;
  }
  p.subtitle {
    margin-bottom: 15px;
    padding: 0;
    letter-spacing: .1em;
    text-indent: 0.1em;
    text-align: center;
    strong {
      color:#125FB9;
      font-size:20px;
    }
  }
}

// 機能・料金比較
.serviceCont4 {
  .hybs_service_price_table {
    th {
      background:$light-gray;
      border-bottom:1px solid $white;
      font-weight:normal;
    }
    tr.header {
      th {
        background:#333333;
        border-right:1px solid $white;
        color:$white;
        font-weight:bold;
      }
    }
    td {
      border-bottom:1px solid $gray;
      border-right:1px solid $gray;
    }
  }
}

// プラン詳細
.serviceCont6 {
  h2.hybs_cont_top_title {
    background:url(/hs_data/Image/service_plan_self_title.gif) no-repeat;
    height:222px;
  }
  div.exbox {
    margin-bottom:3px;
    padding-top:7px;
  }
  div.box1 {
  }
  div.borderbox {
    border:1px solid $color7;
    color:$color4;
    padding:12px 25px;
    border-radius: 5px;
    strong {
      color:$color7;
      font-size:20px;
    }
    p {
      padding-bottom:1em;
      strong {
        color:$dark-gray;
        font-size:14px;
        font-weight:bold;
      }
    }
  }
  div.box1_1 {
    table {
      th {
        background:$color12;
        img {
          display:block;
          zoom:1;
        }
      }
    }
    dl {
      font-size:12px;
      padding:16px 22px;
    }
    dt {
      border-color:$color4;
      color:$color4;
      font-weight:bold;
    }
    dd {
      color:$black-gray;
      font-weight:normal;
      padding-bottom:18px;
    }
  }
  div.banbox {
    padding-bottom:35px;
  }
  div.block2 {
    strong {
      border-color:$color4;
      color:$color4;
      font-size:20px;
      line-height:2em;
    }
  }
  div.block3 {
    padding-top:20px;
    strong {
      border-color:$color4;
      color:$color4;
    }
    table {
      border-collapse:collapse;
      td {
        padding:6px;
      }
      tr.odd {
        td {
          background:$light-gray;
        }
      }
      tr {
        td {
          border:1px solid $gray;
          font-size:12px;
        }
      }
    }
    table.nostyle {
      td {
        border:none;
        padding:0;
      }
    }
  }
  table.plan_table {
    border:2px solid $deep-gray;
    border-collapse:collapse;
    border-width:2px 0;
    td {
      border:1px solid $gray;
      border-width:0 0 1px 1px;
      font-size:12px;
      font-weight:bold;
      padding:3px 0;
    }
    th {
      border:1px solid $gray;
      border-width:0 0 1px 0;
      font-size:12px;
      font-weight:normal;
      padding-right:2px;
    }
    tr.head {
      td {
        border:1px solid $gray;
        border-width:0 0 1px 1px;
        color:$dark-gray;
        font-size:14px;
        font-weight:bold;
        padding:7px 2px;
      }
    }
    colgroup.bg {
      background:url(/hs_data/Image/service_plan_slf_table_bg.jpg);
    }
  }
}
div.borderbox {
  strong {
    border-color:$color4;
    color:$color4;
  }
}
div.block3 {
  table {
    tr {
      th {
        border:1px solid $gray;
        font-size:12px;
      }
    }
  }
  strong {
    border-color:$color3;
    color:$color3;
  }
}

// セルフプラン
.serviceCont6_1{
  .plan_table{
    td.col1,
    tr.head .col1 {
      border-bottom:1px solid #6DA0B3;
    }
  }
}


// マネージドプラン
.serviceCont6_2 {
  h2.hybs_cont_top_title {
    background:url(/hs_data/Image/service_plan_mngd_title.gif) no-repeat;
    height:222px;
  }
  div.exbox {
    margin-bottom:3px;
    padding-top:7px;
  }
  div.box1 {
  }
  div.borderbox {
    border-color: $color3;
    strong {
      border-color:$color3;
      color:$color3;
      font-size:20px;
    }
    p {
      padding-bottom:1em;
      strong {
        color:$dark-gray;
        font-size:14px;
        font-weight:bold;
      }
    }
  }
  div.box1_1 {
    table {
      th {
        background:$color12;
        img {
          display:block;
          zoom:1;
        }
      }
    }
    dl {
      font-size:12px;
      padding:16px 22px;
    }
    dt {
      color:$color3;
      font-weight:bold;
    }
    dd {
      color:$black-gray;
      font-weight:normal;
      padding-bottom:18px;
    }
  }
  div.banbox {
    padding-bottom:35px;
  }
  div.block2 {
    strong {
      border-color:$color3;
      color:$color3;
      font-size:20px;
      line-height:2em;
    }
  }
  div.block3 {
    padding-top:20px;
    table {
      border-collapse:collapse;
      td {
        padding:6px;
      }
      tr.odd {
        td {
          background:$light-gray;
        }
      }
      tr {
        td {
          border:1px solid $gray;
          font-size:12px;
        }
        th {
          border:1px solid $gray;
          font-size:12px;
        }
      }
    }
    table.nostyle {
      td {
        border:none;
        padding:0;
      }
    }
  }
  table.plan_table {
    border-collapse:collapse;
    border:2px solid $deep-gray;
    border-width:2px 0;
    td {
      border:1px solid $gray;
      border-width:0 0 1px 1px;
      font-size:12px;
      font-weight:bold;
      padding:3px 0;
    }
    th {
      border:1px solid $gray;
      border-width:0 0 1px 0;
      font-size:12px;
      font-weight:normal;
      padding-right:2px;
    }
    tr.head {
      td {
        border:1px solid $gray;
        border-width:0 0 1px 1px;
        color:$dark-gray;
        font-size:14px;
        font-weight:bold;
        padding:7px 2px;
      }
      td.col2 {
        border-bottom:1px solid #BBB467;
      }
    }
    colgroup.bg {
      background:url(/hs_data/Image/service_plan_mng_table_bg.jpg);
    }
    td.col2 {
      border-bottom:1px solid #BBB467;
    }
  }
}
div.box1_1 {
  dt {
    border-color:$color3;
    color:$color3;
  }
}
// フルマネージドプラン
.serviceCont6_3 {
  h2.hybs_cont_top_title {
    background:url(/hs_data/Image/service_plan_fmngd_title.gif) no-repeat;
    height:222px;
  }
  div.exbox {
    margin-bottom:3px;
    padding-top:7px;
  }
  div.box1 {
  }
  div.borderbox {
    border:1px solid $color2;
    color:$color2;
    padding:12px 25px;
    strong {
      border-color:$color2;
      color:$color2;
      font-size:20px;
    }
    p {
      padding-bottom:1em;
      strong {
        color:$dark-gray;
        font-size:14px;
        font-weight:bold;
      }
    }
  }
  div.box1_1 {
    table {
      th {
        background:$color12;
        img {
          display:block;
          zoom:1;
        }
      }
    }
    dl {
      font-size:12px;
      padding:16px 22px;
    }
    dt {
      border-color:$color2;
      color:$color2;
      font-weight:bold;
    }
    dd {
      color:$black-gray;
      font-weight:normal;
      padding-bottom:18px;
    }
  }
  div.banbox {
    padding-bottom:35px;
  }
  div.block2 {
    strong {
      border-color:$color2;
      color:$color2;
      font-size:20px;
      line-height:2em;
    }
  }
  div.block3 {
    padding-top:20px;
    strong {
      border-color:$color2;
      color:$color2;
    }
    table {
      border-collapse:collapse;
      td {
        padding:6px;
      }
      tr.odd {
        td {
          background:$light-gray;
        }
      }
      tr {
        td {
          border:1px solid $gray;
          font-size:12px;
        }
      }
    }
    table.nostyle {
      td {
        border:none;
        padding:0;
      }
    }
  }
  table.plan_table {
    border:2px solid $deep-gray;
    border-collapse:collapse;
    border-width:2px 0;
    td {
      border:1px solid $gray;
      border-width:0 0 1px 1px;
      font-size:12px;
      font-weight:bold;
      padding:3px 0;
    }
    th {
      border:1px solid $gray;
      border-width:0 0 1px 0;
      font-size:12px;
      font-weight:normal;
      padding-right:2px;
    }
    tr.head {
      td {
        border:1px solid $gray;
        border-width:0 0 1px 1px;
        color:$dark-gray;
        font-size:14px;
        font-weight:bold;
        padding:7px 2px;
      }
      td.col3 {
        border-bottom:1px solid #A3B055;
      }
    }
    colgroup.bg {
      background:url(/hs_data/Image/service_plan_fmg_table_bg.jpg);
    }
    td.col3 {
      border-bottom:1px solid #A3B055;
    }
  }
}
.serviceCont6_3div.block3 {
  table {
    tr {
      th {
        border:1px solid $gray;
        font-size:12px;
      }
    }
  }
}

// オリジナルプラン
.serviceCont6_4 {
  h2.hybs_cont_top_title {
    background:url(/hs_data/Image/service_plan_ori_title.gif) no-repeat;
    height:222px;
  }
  div.exbox {
    margin-bottom:3px;
    padding-top:7px;
  }
  div.box1 {
  }
  div.borderbox {
    border:1px solid $color1;
    color:$color1;
    padding:12px 25px;
    strong {
      border-color:$color1;
      color:$color1;
      font-size:20px;
    }
    p {
      padding-bottom:1em;
      strong {
        color:$dark-gray;
        font-size:14px;
        font-weight:bold;
      }
    }
  }
  div.box1_1 {
    table {
      th {
        background:$color12;
        img {
          display:block;
          zoom:1;
        }
      }
    }
    dl {
      font-size:12px;
      padding:16px 22px;
    }
    dt {
      border-color:$color1;
      color:$color1;
      font-weight:bold;
    }
    dd {
      color:$black-gray;
      font-weight:normal;
      padding-bottom:18px;
    }
  }
  div.banbox {
    padding-bottom:35px;
  }
  div.block2 {
    strong {
      border-color:$color1;
      color:$color1;
      font-size:20px;
      line-height:2em;
    }
  }
  div.block3 {
    padding-top:20px;
    strong {
      border-color:$color1;
      color:$color1;
    }
    table {
      border-collapse:collapse;
      td {
        padding:6px;
      }
      tr.odd {
        td {
          background:$light-gray;
        }
      }
      tr {
        td {
          border:1px solid $gray;
          font-size:12px;
        }
        th {
          border:1px solid $gray;
          font-size:12px;
        }
      }
    }
    table.nostyle {
      td {
        border:none;
        padding:0;
      }
    }
  }
  div.block4 {
    strong {
      border-color:$color1;
      color:$color1;
      font-size:20px;
    }
  }
  table.plan_table {
    border:2px solid $deep-gray;
    border-collapse:collapse;
    border-width:2px 0;
    td {
      border:1px solid $gray;
      border-width:0 0 1px 1px;
      font-size:12px;
      font-weight:bold;
      padding:3px 0;
    }
    th {
      border:1px solid $gray;
      border-width:0 0 1px 0;
      font-size:12px;
      font-weight:normal;
      padding-right:2px;
    }
    tr.head {
      td {
        border:1px solid $gray;
        border-width:0 0 1px 1px;
        color:$dark-gray;
        font-size:14px;
        font-weight:bold;
        padding:7px 2px;
      }
      td.col4 {
        border-bottom:1px solid #888888;
      }
    }
    colgroup.bg {
      background:url(/hs_data/Image/service_plan_ori_table_bg.jpg);
    }
    td.col4 {
      border-bottom:1px solid #888888;
    }
  }
}

// 充実のサポート
.serviceCont7 {
  h2.hybs_cont_top_title {
    margin-bottom: 0;
    img {
      @include media-down(lg) {
        width: 100%;
        height: auto;
      }
    }
  }
  .box1,
  .box2,
  .box3 {
    padding: 31px 0;
    border-top: 2px solid $gray;
    @include media-up(lg) {
      padding:31px 22px;
    }
    strong {
      font-size: $font-size-base * 1.25;
      font-weight: $font-weight-bold;
      color: $color6;
      line-height: 1.6;
    }
    p {
      padding:7px 10px;
      line-height: 1.6;
    }
    td.icon {
      @include media-up(lg) {
        width: 102px;
        padding-right: 10px;
      }
    }
  }

  .box1table {
    table {
      border-width:1px 0;
      border:1px solid $color6;
      th, td {
        @include media-down(lg) {
          font-size: $font-size-xs;
        }
      }
      td {
        border-right:1px solid $color6;
        padding:7px;
      }
      th {
        color:$color6;
        border-right:1px solid $color6;
        padding:7px;
      }
      .nobdr {
        border-right:none;
      }
    }
    tr.head {
      background: linear-gradient($color6,0%, $white,150%);
      th {
        height: 34px;
        padding:0;
        background-color: rgba($color6, 80%);
        color:$white;
        font-size: $font-size-lg;
        font-weight: $font-weight-normal;
        text-align: center;
      }
    }
    tr.odd {
      th {
        background:#EEEEEE;
      }
      td {
        background:#EEEEEE;
      }
    }
  }
  .box4 {
    background:url(/hs_data/Image/service_support_boxbg_tb.gif) no-repeat;
    height:13px;
  }
}

.serviceCont8 {
  h2.hybs_cont_top_title {
    background:url("/hs_data/Image/service_use_title.gif") no-repeat scroll 0 0 transparent;
    height:60px;
  }
  strong.hybs_img {
    background:url("/hs_data/Image/service_use_subtitle.gif") no-repeat scroll 0 0 transparent;
    height:37px;
  }
  div.entry {
    dl {
      margin:0;
    }
    table {
      margin-bottom:55px;
      width:100%;
      th {
        text-align:right;
      }
      td {
        p {
          font-size:1.25em;
        }
        span {
        }
      }
    }
  }
  dl {
    padding:0 10px;
    dt {
      color:#3864B9;
      font-size:16px;
      font-weight:bold;
    }
    dd {
      font-size:1.11em;
      padding:0 0 27px 15px;
      ul {
        list-style-position:inside;
        list-style:none;
        text-indent:-0.5em;
      }
      li {
        font-size:1.11em;
      }
    }
  }
}

// サービス内容トップ
.service2013 {
  margin-bottom: 60px;
  p {
    color: #5c5c5c;
    font-size: 95%;
    line-height: 1.7;
    margin-bottom: 10px;
  }
  .linkBtn {
    background-repeat: no-repeat;
    &:hover {
      -moz-opacity:0.8;
      filter: alpha(opacity=80);
      opacity:0.8;
    }
  }
  .mainBlock {
    margin-bottom: 25px;
    .pageTitle {
      text-align: center;
      @include media-down(lg) {
        margin-bottom: 1rem;
      }
    }
    p {
      margin: 15px 0;
    }
  }
  .pageSubTitle {
    margin-bottom: 30px;
    text-align: center;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .pointBlock {
    border-bottom: 1px solid #d6e2e9;
    border-top: 1px solid $white;
    padding: 30px 20px 20px;
    .blockTitle {
      display: inline-flex;
      align-items: center;
      color: #004abb;
      float: left;
      font-weight: $font-weight-bold;
      font-size: $font-size-base * 1.5;
      line-height: 1.6;
      margin-bottom: 5px;
      padding-bottom: 10px;
      width: 315px;
      @include media-down(lg) {
        font-size: $font-size-base * 1.25;
      }
    }
    .imgBlock {
      float: right;
      @include media-down(lg) {
        float: none;
      }
    }
    .txtBlock {
      float: left;
      margin-right: -2em;
      width: 365px;
      p {
        span {
          color: #ff8c35;
          font-weight: bold;
        }
      }
      .linkBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        padding: 6px 10px;
        border-radius: 5px;
        background-color: #ff8c35;
        color: $white;
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
        text-decoration: none;
        text-shadow: 0 none;
        text-align: center;
        @include media-up(lg) {
          display: inline-flex;
          height: 30px;
          padding: 6px 12px;
          font-size: $font-size-sm;
          letter-spacing: .1em;
          text-outline: 0.1em;
        }
        @include media-down(lg) {
          width: 80%;
          margin: 1rem auto;
        }
      }
    }
    .num {
      display: block;
      padding: 4px 8px;
      background-color: $color6;
      font-size: .75rem;
      letter-spacing: .1em;
      line-height: 1.4;
      color: $white;
      @include media-up(lg) {
        margin-right: 1rem;
      }
      @include media-down(lg) {
        margin-bottom: .625rem;
      }
    }
  }
  .noflow {
    .blockTitle {
      float: none;
      margin-right: 0;
      width: 100%;
      @include media-down(lg) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0.5em;
      }
    }
    .txtBlock {
      float: none;
      margin-right: 0;
      width: 100%;
    }
  }
  .pointBlock2 {
    background-color: #f6fcff;
    .blockTitle {
      background-position: left -57px;
    }
  }
  .pointBlock4 {
    background-color: #f6fcff;
    .blockTitle {
      background-position: left -171px;
    }
    .txtBlock {
      .linkBtn {
      }
    }
  }
  .pointBlock1 {
    border-top: 0 none;
    padding-right: 10px;
    padding-top: 0;
  }
  .pointBlock3 {
    padding-bottom: 0;
    padding-right: 0;
    .blockTitle {
      background-position: left -114px;
    }
    .imgBlock {
      padding-top: 10px;
    }
  }
  .pointBlock5 {
    border-bottom: 0 none;
    .blockTitle {
      background-position: left -228px;
    }
    .imgBlock {
      padding-top: 25px;
    }
  }
  .featureBlock {
    max-width: 100%;
    width: 595px;
    margin-right: auto;
    padding-top: 0;
    padding-bottom: 40px;
    background-position: left top;
    background-repeat: no-repeat;
    @include media-up(lg) {
      padding-top: 130px;
      padding-bottom: 95px;
    }
    .blockTitle {
      margin-bottom: 25px;
      font-weight: $font-weight-bold;
      @include media-down(lg) {
        margin-bottom: 0.5em;
        font-size: $font-size-base * 1.25;
        letter-spacing: .07em;
      }
    }
    .imgBlock {
      @include media-up(lg) {
        display: none;
      }
      @include media-down(lg) {
        text-align: center;
        margin-bottom: 1em;
      }
    }
    .txtBlock {
      width: 100%;
      @include media-up(lg) {
        width: 290px;
      }
      >p {
        line-height: 1.4;
        @include media-up(lg) {
          font-size: $font-size-xs;
        }
      }
      .linkBtn {
        @include hoverOpacity;
        color: $white;
      }
    }
  }
  .featureBlock1 {
    @include media-up(lg) {
      background-image: url("/hs_data/content2/up_img/Image/serviceBlock2Img1.png");
    }
    .blockTitle {
      color: #0b8f52;
    }
    
    .imgBlock {
    }
    .txtBlock {
      @include media-up(lg) {
        float: left;
        margin-top: 25px;
        padding-left: 35px;
      }
    }
  }
  .featureBlock2 {
    @include media-up(lg) {
      background-image: url("/hs_data/content2/up_img/Image/serviceBlock2Img2.png");
    }
    .blockTitle {
      color: #015cd8;
    }
    .imgBlock {
    }
    .txtBlock {
      @include media-up(lg) {
        float: right;
        margin-top: 25px;
        padding-right: 35px;
      }
    }
  }
}

// スマホサイトがラクラク作成。スマートフォンサイトも自動で変換。
.smartphone2013 {
  margin-bottom: 60px;
  .pageTitle {
    margin-bottom: 25px;
    text-align: center;
    img {
    }
  }
  .pageMenu {
    list-style:none outside none;
    margin: 0 -6px 15px;
    li {
      float: left;
      margin: 0 6px;
      width: 189px;
      a {
        background: url("/hs_data/content2/up_img/Image/serviceS_PhoneBtn.png") no-repeat center top;
        display: block;
        height: 61px;
        &:hover {
          -moz-opacity:0.8;
          filter: alpha(opacity=80);
          opacity:0.8;
        }
      }
    }
    .menu1 {
      a {
        background-position: 0 top;
      }
    }
    .menu2 {
      a {
        background-position: -190px top;
      }
    }
    .menu3 {
      a {
        background-position: -380px top;
      }
    }
  }
  .pointBlock {
    background: url("/hs_data/content2/up_img/Image/serviceS_PhoneBlockShadow.png") no-repeat center bottom;
    margin-bottom: 15px;
    padding-bottom: 21px;
    .blockTitle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: $font-weight-bold;
      font-size: $font-size-base * 1.625;
      line-height: 1.5;
      margin-bottom: 25px;
      @include media-up(lg) {
        background: url("/hs_data/content2/up_img/Image/serviceS_PhoneMark.png") no-repeat left top;
        height: 79px;
        padding-left: 90px;
      }
      @include media-down(lg) {
        align-items: flex-start;
        flex-direction: column;
        font-size: 1.4rem;
      }
      .num {
        @include media-up(lg) {
          display: none;
        }
        @include media-down(lg) {
          font-size: $font-size-sm;
        }
      }
    }
    .detailBlock {
    }
    .imgBlock {
      @include media-up(lg) {
        float: right;
      }
      @include media-down(lg) {
        margin-bottom: 1rem;
        text-align: center;
      }
    }
    .txtBlock {
      @include media-up(lg) {
        float: left;
      }
      strong {
        color: #484848;
        display: block;
        font-size: $font-size-lg;
        margin-bottom: 5px;
      }
      p {
        color: #5c5c5c;
        font-size: $font-size-base * .8125;
        line-height: 1.7;
        letter-spacing: .1em;
      }
    }
  }
  .pointBlock1 {
    @include media-up(lg) {
      padding-bottom: 66px;
    }
    .blockTitle {
      color: #f0a608;
      line-height: 1.3em;
      padding-bottom: 2px;
      padding-top: 12px;
      span {
      }
    }
    .detailBlock {
    }
    .imgBlock {
      padding-top: 10px;
      @include media-up(lg) {
        padding-top: 30px;
        margin-bottom: -220px;
      }
      @include media-down(lg) {
        text-align: center;
      }
      img {
        @include media-down(lg) {
          max-width: 100%;
        }
      }
    }
  }
  .pointBlock2 {
    .blockTitle {
      color: #67c217;
      @include media-up(lg) {
        background-position: left -81px;
      }
      span {
      }
    }
    .detailBlock {
    }
    .imgBlock {
      img {
      }
    }
  }
  .pointBlock3 {
    @include media-up(lg) {
      padding-bottom: 121px;
    }
    .blockTitle {
      color: #1bb5cc;
      @include media-up(lg) {
        background-position: 0 -162px;
      }
      span {
      }
    }
    .detailBlock {
    }
    .imgBlock {
      @include media-up(lg) {
        margin-bottom: -250px;
      }
      img {
      }
    }
  }
}

//スマートフォン表示バージョンアップ
.SmartPhone {
  @include media-up(lg) {
    padding: 0 30px;
  }
  p.text {
    background-color: #DFDFDF;
    padding: 15px;
    text-align: center;
    font-size: $font-size-base * .8125;
    @include media-down(lg) {
      text-align: left;
    }
  }
  p.text2 {
    color: #898989;
    font-size: $font-size-sm;
  }

  p.link {
    padding: 0 0 20px 30px;
    a {
      color: $primary;
      text-decoration: underline;
    }
  }

  .img {
    text-align: center;
  }

  .pointArea {
    padding: 0 0 15px;
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 15px;
    @include media-up(lg) {
      padding: 25px 20px 0;
      margin-bottom: 40px;
    }
  }
  .pointBlock {
    margin-bottom: 30px;
    @include media-up(lg) {
      margin-bottom: 50px;
    }
    .pointTitle {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      padding-bottom: 6px;
      border-bottom: 1px solid $primary;
      font-size: $font-size-base * 1.25;
      color: $primary;
      line-height: 1.4;
      @include media-down(lg) {
        font-size: $font-size-lg;
      }
      .num {
        font-size: $font-size-sm;
        @include media-down(serviceCont2lg) {
          display: block;
          margin-bottom: 0.3em;
          font-size: $font-size-xs;
        }
        @include media-up(lg) {
          margin-right: 0.5em;
        }
      }
    }
    p {
      margin: 20px 0;
      @include media-up(lg) {
        padding: 0 20px;
      }
    }
  }
}








